<template>
  <div class="maxHeight">
    <div class="header flex">
      <div class="title flex">
        <img src="../../../assets/img/ex_c.png" alt="" width="28">
        <h2>采购确认</h2>
      </div>
      <el-button size="small" @click="$router.go(-1)">返回上一页</el-button>
    </div>
    <div class="activeInfo">
      <span>实验编号：{{ subDetail.number }}</span>
      <span>项目负责人：{{ subDetail.dirNickname }}</span>
      <span>计划开始时间：{{ subDetail.startTime | formatDay }}</span>
      <span>动物品系：{{ subDetail.strain }}</span>
      <p>实验名称：{{ subDetail.name }}</p>
    </div>
    <div class="commonClass">
      <h3 class="title">请购实验动物信息</h3>
      <template v-if="isProd==1">
        <div class="desc">
          <span>动物品系：{{ activeInfo.strain }}</span>
          <span>笼舍数量：{{ activeInfo.applyCageNum }}</span>
          <span>等级：{{ activeInfo.grade == 0 ? '普通' : activeInfo.grade == 1 ? 'SPF' : '清洁级' }}</span>
          <span>是否特殊基因合笼：{{ activeInfo.isGene == 1 ? '是' : '否' }}</span>
          <p v-if="activeInfo.isGene==1">基因类型：{{ activeInfo.geneKeys }}</p>
        </div>
        <el-table
            :data="activeInfo.aniOrderDetailList"
            border
            class="tableMember"
            :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
        >
          <el-table-column label="基因类型" v-if="activeInfo.isGene==1">
            <el-table-column :label="item.title" v-for="(item,ind) in typeList" :key="ind">
              <template slot-scope="scope">
                {{ scope.row.typeList[ind].type }}
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="性别">
            <template slot-scope="scope">
              {{ scope.row.gender == 0 ? '♀' : '♂' }}
            </template>
          </el-table-column>
          <el-table-column label="数量" show-overflow-tooltip prop="count">
          </el-table-column>
          <el-table-column label="体重(g)" prop="weight">
          </el-table-column>
          <el-table-column label="周龄" prop="weekDays">
          </el-table-column>
        </el-table>
      </template>
      <template v-else>
        <div class="desc">
          <p>
            <span>品系：{{ activeInfo.strain }}</span>
            <span>申请笼舍数量：{{ activeInfo.applyCageNum }}</span>
          </p>
          <p>
            <span>雌性周龄：{{ female.weekDays }}周</span>
            <span>雌性体重：{{ female.weight }}g</span>
            <span>雌性数量：{{ female.count }}</span>
          </p>
          <p>
            <span>雄性周龄：{{ male.weekDays }}周</span>
            <span>雄性体重：{{ male.weight }}g</span>
            <span>雄性数量：{{ male.count }}</span>
          </p>
          <p>等级：{{ activeInfo.grade == 0 ? '普通' : activeInfo.grade == 1 ? 'SPF' : '清洁级' }}</p>
        </div>
      </template>
      <h3 class="title">供货信息</h3>
      <div class="aniSupply">
        <template v-if="activeInfo.isLabAni==1">
          <span><label>来源：</label>实验室</span>
        </template>
        <p v-if="activeInfo.isLabAni!=1">
          <span><label>来源：</label>{{ activeInfo.aniSupply.supplier }}</span>
          <span><label>许可证：</label>{{ activeInfo.aniSupply.prodCert }}</span>
        </p>
      </div>
      <template v-if="state==0">
        <el-form ref="addForm" size="small" :rules="rules" :model="addExper" class="addForm" label-suffix=":"
                 label-width="86px">
          <div class="flex head">
            <el-form-item label="合格证号">
              <el-input v-model="addExper.certNo" class="smallWidth" placeholder="请输入合格证号"></el-input>
            </el-form-item>
            <el-form-item label="运输工具">
              <el-input v-model="addExper.transport" class="smallWidth" placeholder="请输入运输工具"></el-input>
            </el-form-item>
          </div>
          <div class="flex head">
            <el-form-item label="单位负责人">
              <el-input v-model="addExper.supplyPrincipal" class="smallWidth" placeholder="请输入单位负责人"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="supplyPhone">
              <el-input v-model="addExper.supplyPhone" class="smallWidth" placeholder="请输入联系电话"></el-input>
            </el-form-item>
          </div>
          <div class="fromSave">
            <el-button type="primary" size="small" @click="submit('addForm')">采购完成</el-button>
          </div>
        </el-form>
      </template>
      <template v-else>
        <div class="aniSupply">
          <span><label>合格证号：</label>{{ activeInfo.aniSupply.certNo }}</span>
          <span><label>运输工具：</label>{{ activeInfo.aniSupply.transport }}</span>
        </div>
        <div class="aniSupply">
          <span><label>单位负责人：</label>{{ activeInfo.aniSupply.supplyPrincipal }}</span>
          <span><label>联系电话：</label>{{ activeInfo.aniSupply.supplyPhone }}</span>
        </div>
      </template>

    </div>

  </div>

</template>

<script>
export default {
  name: "proDetail",
  data() {
    let checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
      if (!value) {
        return callback(new Error('电话号码不能为空'))
      }
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('电话号码格式不正确'))
          }
        }
      }, 100)
    };
    return {
      subId: this.$route.query.id,
      state: this.$route.query.state,
      isProd: this.$route.query.isProd,
      subDetail: {},
      activeInfo: {
        aniSupply: {},
        detail: {},
        aniOrder: {
          aniSupply: {}
        }
      },
      typeList: [],
      addExper: {
        subId: this.$route.query.id,
        certNo: "",
        transport: "",
        supplyPrincipal: "",
        supplyPhone: "",
      },
      rules: {
        "supplyPhone": [
          {validator: checkPhone, trigger: 'blur'}],
      },
      // 雌性
      female: {},
      // 雄性
      male: {},
    }
  },
  mounted() {
    let that = this;
    // 查询基本信息
    that.$get("/subject/" + this.subId).then((res) => {
      if (res.status == 200) {
        this.subDetail = res.data
      }
    }).catch(() => {
      this.$message.error('获取数据失败')
    })

    // 查询动物信息
    that.$get("/subject/order/buy/" + this.subId).then((res) => {
      if (res.status == 200) {
        that.activeInfo = res.data

        if (this.isProd == 1) {
          // 表格内的基因类型
          res.data.aniOrderDetailList.forEach(obj => {
            obj.typeList = JSON.parse(obj.gene)
          })

          // 赋值typeList
          let arr = this.activeInfo.geneKeys.split(',')
          arr.forEach(item => {
            this.typeList.push({
              title: item
            })
          })
        }
      // 实验课题
        if (this.isProd == 0) {
          // 雌雄性数量
          res.data.aniOrderDetailList.forEach(obj => {
            if (obj.gender == 0) {
              this.female = obj
            } else {
              this.male = obj
            }
          })
        }
      }
    }).catch(() => {
      this.$message.error('获取数据失败')
    })
  },
  methods: {
    submit(task) {
      this.$refs[task].validate((valid) => {
        if (valid) {
          this.addExper.state = 1
          this.addExper.id = this.activeInfo.aniSupply.id
          this.addExper.buyId = this.activeInfo.aniBuy.id
          this.$put("/supply", this.addExper).then(res => {
            this.$message.success('提交成功')
            this.$router.go(-1)
          }).catch(() => {
            this.$message.error('提交失败')
          })
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/css/proDetail";
</style>
